import { CallStats } from "@/google/callStats";
import { CallResult } from "@/models/CallHistory";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CallStatsByInterval = Array<{
  [key in CallResult]: number;
}>;

type CommonState = {
  callStatsByInterval: CallStatsByInterval;
  stats: {
    [label: string]: CallStats;
  };
  currFilterLabel: string;
  displayStats: CallStats | null;
};

const initialState: CommonState = {
  callStatsByInterval: [],
  stats: {},
  currFilterLabel: "",
  displayStats: null,
};

const callStatsSlice = createSlice({
  name: "callStats",
  initialState,
  reducers: {
    initCallStatsSlice: (state, action: PayloadAction) => {
      Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
        state[key] = value;
      });
      console.debug("call slice initialized");
    },
    setCallStatsByInterval: (
      state,
      action: PayloadAction<{ [label: string]: CallStats }>,
    ) => {
      state.stats = {
        ...action.payload,
      };
    },
    setCallStats: (state, action: PayloadAction<[string, CallStats]>) => {
      state.currFilterLabel = action.payload[0];
      state.stats[action.payload[0]] = action.payload[1];
      state.displayStats = action.payload[1];
    },
    setFilterLabel: (state, action: PayloadAction<string>) => {
      state.currFilterLabel = action.payload;
      state.displayStats = state.stats[action.payload];
    },
  },
});

export const {
  initCallStatsSlice,
  setCallStatsByInterval,
  setCallStats,
  setFilterLabel,
} = callStatsSlice.actions;
export default callStatsSlice.reducer;
