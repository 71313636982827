import {  Middleware } from "redux";
import { AppDispatch, RootState } from "./store";
import { TelaiSocketClient } from "@/models/telai-backend/TelaiSocketClient";
import { getIdToken } from "@/google/auth";
import {  getCurrentUser, getUser } from "./userSlice";
import {
  deleteLocalCallProcess,
  getCall,
  getCallProcess,
} from "./callSlice";
import { BATCH_STATUS } from "@/models/CallBatch";

export const telaiSocketMiddleware = (url: string) => {
  const client = new TelaiSocketClient(`${url}`);

  const middleware: Middleware<object, RootState> =
    (params) => (next) => async (action: { type: string }) => {
      const {
        dispatch,
        getState,
      }: {
        dispatch: AppDispatch;
        getState: () => RootState;
      } = params;
      const { type } = action;

      switch (type) {
        case "socket/connect": {
          if (
            client.getState() === "CONNECTING" ||
            client.getState() === "CONNECTED" ||
            client.getState() === "RECONNECTING"
          )
            return;

          client.on("beforeReconnect", async () => {
            client.updateToken(await getIdToken());
          });

          client.on("error", (error) => {
            console.error(error);
          });

          // user.updated
          client.removeAllListeners("user.updated");
          client.on("user.updated", (data) => {
            console.log("user updated: " + data.id);
            dispatch(getUser({ uid: data.id }));
          });

          client.removeAllListeners("call.created");
          client.on("call.created", async (data) => {
            console.log("call created: " + data.id);
            await dispatch(getCall({ callId: data.id }));
          });
          client.removeAllListeners("call.updated");
          client.on("call.updated", async (data) => {
            console.log("call updated: " + data.id);
            await dispatch(getCall({ callId: data.id }));
          });

          client.removeAllListeners("callBatch.created");
          client.removeAllListeners("callBatch.updated");
          // callBatch.created, callBatch.updated
          client.removeAllListeners("callBatch.deleted");
          const callBatchOnCreatedOrUpdated = async (data) => {
            console.log("call batch updated: " + data.id);
            await dispatch(
              getCallProcess({
                id: data.id as string,
              }),
            );
            const process = getState().call.callProcesses[data.id];

            // コールプロセスを削除しないが非表示にするための対応
            if (
              process.status &&
              [
                BATCH_STATUS.Completed as string,
                BATCH_STATUS.Aborted as string,
              ].includes(process.status)
            ) {
              dispatch(deleteLocalCallProcess(data.id));
            }
          };
          client.on("callBatch.created", callBatchOnCreatedOrUpdated);
          client.on("callBatch.updated", callBatchOnCreatedOrUpdated);
          client.on("callBatch.deleted", async (data) => {
            dispatch(deleteLocalCallProcess(data.id));
          });

          client.connect(await getIdToken());
          break;
        }
        case "socket/disconnect":
          client.disconnect();
          break;
      }
      next(action);
    };
    return middleware;
};