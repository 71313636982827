import { app } from "./firebase";
import {
  initializeAuth,
  browserLocalPersistence,
  User,
  signInWithEmailAndPassword,
  signInWithEmailLink as _signInWithEmailLink,
  signOut,
  getIdToken,
  getAuth,
  sendSignInLinkToEmail as _sendSignInLinkToEmail,
  isSignInWithEmailLink as _isSignInWithEmailLink,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import store from "@/store/store";
import { request } from "@/models/telai-backend/client";

const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

const getCurrentUserAccount = async (): Promise<User | null> => {
  const user: User | null = await new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged((user) => resolve(user));
    } catch (err) {
      reject(err);
    }
  });

  return user;
};

const isLoggedIn = async () => {
  const user = await getCurrentUserAccount();
  if (user) {
    return true;
  } else {
    return false;
  }
};

const getCurrentUserInfo = async () => {
  const user = await getCurrentUserAccount();
  if (user) {
    const res = await request({
      path: "/users/me",
      httpMethod: "get",
    });

    if (res.result === "error") {
      throw new Error(res.error.data.message);
    }
    return res.data;
  }
};

const signInWithEmail = (email: string, password: string, tenantId: string) => {
  const _auth = getAuth();
  _auth.tenantId = tenantId;
  return signInWithEmailAndPassword(_auth, email, password);
};

export const checkEmailExists = async (email: string): Promise<boolean> => {
  return fetchSignInMethodsForEmail(auth, email)
    .then((signInMethods) => {
      if (signInMethods.length > 0) {
        // メールアドレスは既に登録されている
        console.log("このメールアドレスは登録されています");
        return true;
      } else {
        // メールアドレスは登録されていない
        console.log("このメールアドレスは登録されていません");
        return false;
      }
    })
    .catch((error) => {
      // エラー処理
      console.error("エラーが発生しました:", error);
      return false;
    });
};

export const sendSignInLinkToEmail = (email: string, tenantId: string) => {
  const _auth = getAuth();
  _auth.tenantId = tenantId;
  _sendSignInLinkToEmail(_auth, email, {
    url: `${window.location.origin}/${tenantId}/signin`,
    handleCodeInApp: true,
  })
    .then(() => {
      window.localStorage.setItem("emailForSignIn", email);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const signInWithEmailLink = (email: string, tenantId: string) => {
  const _auth = getAuth();
  _auth.tenantId = tenantId;
  return _signInWithEmailLink(_auth, email, window.location.href);
};

export const isSignInWithEmailLink = (tenantId: string) => {
  const _auth = getAuth();
  const _tenantId = (() => {
    if (!tenantId) {
      return null;
    }
    return tenantId;
  })();
  _auth.tenantId = _tenantId;
  return _isSignInWithEmailLink(_auth, window.location.href);
};

const signOutCurrentUser = () => {
  signOut(auth);
};

const getIdTokenAuth = async () => {
  const user: User | null = await new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged((user) => resolve(user));
    } catch (err) {
      reject(err);
    }
  });

  if (!user) return "";
  return getIdToken(user);
};

export {
  auth,
  isLoggedIn,
  getCurrentUserInfo,
  signInWithEmail,
  signOutCurrentUser,
  getIdTokenAuth as getIdToken,
};
