import { CallResult } from "@/models/CallHistory";
import { request } from "@/models/telai-backend/client";
import { Dayjs } from "dayjs";

export const sendCallResult = async ({
  callId,
  callResult,
  nextCallDate,
  callMemo,
}: {
  callId: string;
  callResult: CallResult;
  nextCallDate?: Dayjs;
  callMemo?: string;
}) => {
  console.log("send call result");
  return await request({
    path: "/calls/{callId}/note",
    httpMethod: "post",
    params: {
      paths: {
        callId,
      },
      body: {
        content: callMemo,
        result: callResult,
        nextCallScheduledAt: nextCallDate ? nextCallDate.toISOString() : null,
      },
    },
  });
};
