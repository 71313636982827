import { getCurrentUserInfo, getIdToken } from "@/google/auth";
import { request } from "@/models/telai-backend/client";
import { User, UserRole } from "@/models/User";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";

type UserState = {
  loading: boolean;
  error: {
    status: boolean;
    message: string;
  };
  missedManualInboundCallOn: Timestamp | null;
  isSignedIn: boolean;
  idToken: string;
  companyUsers: {
    [uid: string]: User;
  };
  companyUsersFetched: boolean;
  loggedInUser: User;
};

const initialState: UserState = {
  isSignedIn: false,
  idToken: "",
  loading: false,
  error: {
    status: false,
    message: "",
  },
  missedManualInboundCallOn: null,

  companyUsers: {},
  companyUsersFetched: false,
  loggedInUser: {
    id: "",
    name: "",
    email: "",
    roles: ["USER"],
    status: "FREE",
    online: false,
    lastOnlineAt: "",
    assignedCallIds: [],
    assignedCallBatchIds: [],
    unfilledNoteResultCallIds: [],
    tenantId: "",
  },
};

export const getUser = createAsyncThunk<
  User,
  { uid: string },
  { rejectValue: { message: string } }
>("user/getUser", async ({ uid }, { rejectWithValue }): Promise<User> => {
  const res = await request({
    path: "/users/{userId}",
    httpMethod: "get",
    params: {
      paths: { userId: uid },
    },
  });
  if (res.result === "error") {
    console.error(res.error);
    rejectWithValue(res.error.data);
    return;
  }
  return { ...res.data };
});

export const getUsers = createAsyncThunk<
  User[],
  void,
  { rejectValue: { message: string } }
>("user/getUsers", async (_, { rejectWithValue }): Promise<User[]> => {
  const res = await request({
    path: "/users",
    httpMethod: "get",
  });
  if (res.result === "error") {
    console.error(res.error);
    rejectWithValue(res.error.data);
    return;
  }
  return res.data.users;
});

export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (_, { rejectWithValue }): Promise<User & { idToken: string }> => {
    const user = await getCurrentUserInfo();
    if (user) {
      console.log({ user });
      const res = await request({
        path: "/users/me",
        httpMethod: "get",
      });
      if (res.result === "error") {
        console.error(res.error);
        rejectWithValue(res.error.data);
        return;
      }

      const idToken = await getIdToken();
      return {
        ...res.data,
        idToken,
      };
    }
  },
);

export const refreshIdToken = createAsyncThunk(
  "user/refreshIdToekn",
  async () => {
    return await getIdToken();
  },
);

export const resetUserState = createAsyncThunk("user/resetUserState", () => {
  return {
    uid: "",
    name: "",
    email: "",
    companyId: "",
    companyName: "",
    role: "ADMIN" as UserRole,
    createdAt: "",
    updatedAt: "",
    assignedPid: "",
    assignedCallsNumber: 0,
    assignedTwilioUuid: "",
    callState: "NOT_LOGGED_IN",
    assignedCallSid: "",
  };
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateLocalCompanyUsers: (
      state,
      action: PayloadAction<{ [uid: string]: User }>,
    ) => {
      state.companyUsers = {
        ...state.companyUsers,
        ...action.payload,
      };
    },
    initUserSlice: (state) => {
      Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
        state[key] = value;
      });
      console.debug("user slice initialized");
    },
    setMissedManualInboundCallOn: (state, action: PayloadAction<boolean>) => {
      state.missedManualInboundCallOn = action.payload ? Timestamp.now() : null;
    },
    // 自身のステータスを更新する
    setUserStatus: (state, action: PayloadAction<{status: User["status"] }>) => {
      console.log("user status updated", action.payload);
      state.loggedInUser.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload) {
        state.loading = false;
        state.error.status = true;
        state.error.message = "user not found";
        return;
      }

      state.isSignedIn = true;
      state.companyUsers[action.payload.id] = action.payload;
      state.loggedInUser = action.payload;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.companyUsers[action.payload.id] = action.payload;
      if (state.loggedInUser.id === action.payload.id) {
        state.loggedInUser = action.payload;
      }
    });
    builder.addCase(getUser.rejected, (state, action) => {
      console.error(action.error.message);
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      action.payload.forEach((user) => {
        state.companyUsers[user.id] = user;
        if (state.loggedInUser.id === user.id) {
          state.loggedInUser = user;
        }
      });
      state.companyUsersFetched = true;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      console.error(action.error.message);
    });

    builder.addCase(refreshIdToken.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(refreshIdToken.fulfilled, (state, action) => {
      state.idToken = action.payload;
    });

    builder.addCase(resetUserState.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(resetUserState.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload) {
        state.loading = false;
        state.error.status = true;
        state.error.message = "user not found";
        return;
      }
      state.isSignedIn = false;
      state.loggedInUser = {
        ...initialState.loggedInUser,
      };
    });
  },
});

export const {
  initUserSlice,
  updateLocalCompanyUsers,
  setMissedManualInboundCallOn,
  setUserStatus,
} = userSlice.actions;
export default userSlice.reducer;
