import { getScripts } from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let fetching = false;

export const useScripts = ({
  disableLoading = false,
}: { disableLoading?: boolean } = {}) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const scripts = useSelector((state: RootState) => state.call.scripts);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn || fetching) return;
      if (Object.values(scripts).length) return;
      fetching = true;
      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useScripts", state: true }));
      await dispatch(getScripts());
      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useScripts", state: false }));
      fetching = false;
    };
    fetch();
  }, [dispatch, isSignedIn]);

  return scripts;
};
