import { getCallProcesses } from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useCallProcesses = () => {
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const callProcesses = useSelector(
    (state: RootState) => state.call.callProcesses,
  );
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!user.online) return;

    const fetch = async () => {
      dispatch(
        setLoadingBackdrop({ key: "useCallProcessesFetch", state: true }),
      );
      await dispatch(getCallProcesses());
      dispatch(
        setLoadingBackdrop({ key: "useCallProcessesFetch", state: false }),
      );
    };
    fetch();
  }, [isSignedIn, dispatch, user.online]);

  return callProcesses;
};
