import {
  CallStats,
  CallStatsFilter,
  callStatsPeriods,
  getDailyCallSummaries,
} from "@/google/callStats";
import { callResults } from "@/models/CallHistory";
import { setCallStatsByInterval } from "@/store/callStatsSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let prevFilter: CallStatsFilter | null = null;

export const useCallStatsByInterval = (filter: CallStatsFilter) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const callStats = useSelector((state: RootState) => state.callStats.stats);
  const dispatch = useDispatch<AppDispatch>();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn) {
        prevFilter = null;
        return;
      }
      if (!filter || !callStatsPeriods.includes(filter.period)) return;
      if (
        prevFilter &&
        Object.keys(filter).reduce(
          (prev, curr) => prev && filter[curr] === prevFilter[curr],
          true,
        )
      )
        return;
      console.log("fetch call stats by interval");

      try {
        setFetching(true);
        const log = await getDailyCallSummaries(filter);
        if (!log) {
          return;
        }
        const sum: { [date: string]: CallStats } = {};
        log.summaries.forEach((entry) => {
          if (!sum[entry.date]) {
            sum[entry.date] = callResults.reduce((prev, curr) => {
              prev[curr] = 0;
              return prev;
            }, {}) as CallStats;
          }
          sum[entry.date][entry.callNoteResult] =
            (sum[entry.date][entry.callNoteResult] ?? 0) + entry.count;
        });

        dispatch(setCallStatsByInterval(sum));
        prevFilter = filter;
      } catch (e) {
        console.error(e);
      } finally {
        setFetching(false);
      }
    };
    fetch();
  }, [dispatch, isSignedIn, filter]);

  return [callStats, fetching] as const;
};
