import Ajv, { ValidateFunction } from "ajv/dist/2020";
import addFormats from "ajv-formats";
import { EventEmitter } from "events";
import CallBatchNotification from "../../../telai-backend/telai.socket.v1/CallBatchNotification.schema.json";
import CallNotification from "../../../telai-backend/telai.socket.v1/CallNotification.schema.json";
import ContactListNotification from "../../../telai-backend/telai.socket.v1/ContactListNotification.schema.json";
import ScriptMessageNotification from "../../../telai-backend/telai.socket.v1/ScriptMessageNotification.schema.json";
import UserNotification from "../../../telai-backend/telai.socket.v1/UserNotification.schema.json";

const notificationSchemas = [
  CallBatchNotification,
  CallNotification,
  ContactListNotification,
  ScriptMessageNotification,
  UserNotification,
] as const;

type WebSocketState =
  | "CONNECTING"
  | "CONNECTED"
  | "DISCONNECTED"
  | "RECONNECTING";

export class TelaiSocketClient extends EventEmitter {
  private ws: WebSocket | null = null;
  private readonly url: string;
  private readonly validatorMap: Map<string, ValidateFunction>;
  private readonly ajv: Ajv;
  private readonly maxReconnectAttempts = 5;
  private readonly reconnectInterval = 3000;
  private idToken: string;
  private reconnectAttempts = 0;
  private state: WebSocketState = "DISCONNECTED";

  constructor(url: string) {
    super();
    this.url = url;
    this.ajv = addFormats(new Ajv());

    const validatorMap = new Map<string, ValidateFunction>();
    notificationSchemas.forEach((schema) => {
      schema.properties.type.enum.forEach((type: string) => {
        console.log("type: ", type, schema);
        validatorMap.set(type, this.ajv.compile(schema));
      });
    });
    this.validatorMap = validatorMap;
  }

  public connect(idToken: string): void {
    if (this.state === "CONNECTED" || this.state === "CONNECTING") {
      return;
    }

    this.idToken = idToken;

    try {
      this.state = "CONNECTING";
      this.ws = new WebSocket(this.url);
      console.log(this.ws);
      this.setupEventHandlers();
    } catch (error) {
      this.handleError(error as Error);
    }
  }

  public updateToken(idToken: string): void {
    this.idToken = idToken;
  }

  // WebSocket接続を終了
  public disconnect(): void {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.state = "DISCONNECTED";
    this.reconnectAttempts = 0;
  }

  private setupEventHandlers(): void {
    if (!this.ws) return;

    this.ws.onopen = () => {
      this.state = "CONNECTED";
      this.reconnectAttempts = 0;
      this.ws.send(
        JSON.stringify({ type: "auth.request", data: { token: this.idToken } }),
      );
      this.emit("connected", null);
    };

    this.ws.onclose = () => {
      this.state = "DISCONNECTED";
      this.handleReconnect();
    };

    this.ws.onerror = (error) => {
      console.error(error);
    };

    this.ws.onmessage = (event) => {
      this.handleMessage(event);
    };
  }

  private handleMessage(event: MessageEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = JSON.parse(event.data) as { type: string; data: any };

    if (data.type === "auth.response") {
      // 認証成功
      return;
    }
    const validate = this.validatorMap.get(data.type);
    if (!validate) {
      throw new Error("Unknown notification type: " + data.type);
    }

    if (validate(data)) {
      this.emit(data.type, data.data);
    } else {
      console.error("Invalid message format");
    }
  }

  private handleError(error: Error): void {
    this.emit("error", error);
    if (this.state !== "DISCONNECTED") {
      this.handleReconnect();
    }
  }

  // 再接続処理
  private handleReconnect(): void {
    this.emit("beforeReconnect", null);

    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      this.emit("maxReconnectAttemptsReached", null);
      return;
    }

    this.state = "RECONNECTING";
    this.reconnectAttempts++;
    this.emit("reconnecting", this.reconnectAttempts);

    setTimeout(() => {
      this.connect(this.idToken);
    }, this.reconnectInterval);
  }

  public getState(): WebSocketState {
    return this.state;
  }
}
