const softStyleProps = {
  size: "small",
  sx: {
    bgcolor: "#ffffff",
    "& .MuiOutlinedInput-notchedOutline": {
      // border: "none"
    },
  },
} as const;

export default softStyleProps;
