import axios from "axios";
import {
  ApiBody,
  ApiError,
  ApiPath,
  ApiPathParam,
  ApiQueryParam,
  ApiResponse,
  httpErrorStatusCodes,
  HttpMethod,
  HttpMethodsFilteredByPath,
} from "./schemaHelper";
import { getIdToken } from "@/google/auth";

const baseURL = import.meta.env.VITE_TELAI_BACKEND_URL;

type CreateApiClientOption<Path extends ApiPath, Method extends HttpMethod> = {
  path: Path;
  httpMethod: Method & HttpMethodsFilteredByPath<Path>;
  requestType?: "json" | "arraybuffer" | "blob" | "document" | "text" | "stream" | "CSV";
  responseType?:
    | "json"
    | "arraybuffer"
    | "blob"
    | "document"
    | "text"
    | "stream";
  params?: {
    paths?: ApiPathParam<Path, Method>;
    query?: ApiQueryParam<Path, Method>;
    body?: ApiBody<Path, Method>;
  };
};

export type RequestResponse<Path extends ApiPath, Method extends HttpMethod> =
  | { result: "success"; data: ApiResponse<Path, Method> }
  | { result: "error"; error: ApiError<Path, Method> };

export const request = async <Path extends ApiPath, Method extends HttpMethod>(
  option: CreateApiClientOption<Path, Method>,
) => {
  const path = () => {
    // {trial_uid} などとなっているpathを実際の値に変換する
    const fullPath = Object.entries(option.params?.paths ?? {}).reduce(
      (prev, [key, value]) =>
        prev.replace(new RegExp(`\\{${key}\\}`), String(value)),
      option.path as string,
    );

    const searchParam = new URLSearchParams();
    Object.entries(option.params?.query ?? {}).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }

      if (Array.isArray(value)) {
        // 配列の場合は全ての値をセットする
        value.forEach((v) => searchParam.append(key, String(v)));
      } else {
        searchParam.append(key, String(value));
      }

    });
    
    if (searchParam.toString().length > 0) {
      return fullPath + "?" + searchParam.toString();
    }
    return fullPath;
  };

  try {
    const idToken = await getIdToken();
    const res = await axios.request<ApiResponse<Path, Method>>({
      baseURL,
      method: option.httpMethod,
      url: path(),
      data: option.params?.body,
      responseType: option.responseType ?? "json",
      // withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return {
      result: "success",
      data: res.data,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && !!e.response) {
      const errorData = { status: e.response.status, data: e.response.data };
      if (isExpectedError<Path, Method>(errorData)) {
        return {
          result: "error",
          error: errorData,
        };
      }
    }
    console.error(e);
    // throw new Error(e)
  }
};

// schema-utilで定義しているエラーコードのリストにレスポンスのステータスコードが含まれるかチェック
const isExpectedError = <Path extends ApiPath, Method extends HttpMethod>(res: {
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}): res is ApiError<Path, Method> => {
  return httpErrorStatusCodes.map(Number).includes(res.status);
};
