import { PhoneCallback } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

const TossUpNotification = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 20000);
  }, []);

  return (
    <Box
      borderRadius={4}
      bgcolor="#FFEBEE"
      p={4}
      sx={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
      minWidth={340}
    >
      <PhoneCallback color="error" sx={{ fontSize: "4rem" }}></PhoneCallback>
      <Typography color="error" fontWeight="bold" fontSize="1.2rem">
        トスアップされました。<br></br>メイン画面へ移動してください
      </Typography>
    </Box>
  );
};

export default TossUpNotification;
