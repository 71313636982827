import { request } from "@/models/telai-backend/client";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { setCompanyConfig } from "@/store/companySlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let fetching = false;

export const useCompanyConfig = ({
  disableLoading = false,
}: { disableLoading?: boolean } = {}) => {
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const companyConfig = useSelector((state: RootState) => state.company.config);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn || fetching) return;
      if (companyConfig.tenantName) return;
      fetching = true;

      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));

      const res = await request({
        path: "/settings",
        httpMethod: "get",
      });
      if (res.result === "error") {
        throw new Error(res.error.data.message);
      }
      dispatch(setCompanyConfig(res.data));

      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
      fetching = false;
    };
    fetch();
  }, [
    dispatch,
    isSignedIn,
    companyId,
    companyConfig.tenantName,
    disableLoading,
  ]);

  return companyConfig;
};
