import { CallResult } from "@/models/CallHistory";
import axios from "axios";
import { request } from "@/models/telai-backend/client";
import moment from "moment";

const getCallStats = async ({
  companyId,
  period,
  commodityId,
  scriptId,
  callListId,
}: {
  companyId: string;
  period: number;
  commodityId?: string;
  scriptId?: string;
  callListId?: string;
}) => {
  const res = await axios.post(
    import.meta.env.VITE_AGGEREGATE_CALL_RESULT_FOR_PERIOD_URL,
    {
      companyId,
      period,
      commodityId,
      scriptId,
      callListId,
    },
  );
  return res.data;
};

const callStatsPeriods = [
  "TODAY",
  "THIS_WEEK",
  "LAST_WEEK",
  "THIS_MONTH",
  "LAST_MONTH",
  "THIS_YEAR",
] as const;
const callStatsPeriodLabels = {
  TODAY: "今日",
  THIS_WEEK: "今週",
  LAST_WEEK: "先週",
  THIS_MONTH: "今月",
  LAST_MONTH: "先月",
  THIS_YEAR: "今年",
} as const;

type CallStats = {
  [key in CallResult | "TOTAL"]?: number;
} & { final: boolean };

type CallStatsPeriod = (typeof callStatsPeriods)[number];

const showingCallResultOptions: Array<CallResult | "OTHERS"> = [
  "TOSSUP_APPOINTMENT",
  "FINISHED_ON_AI",
  "UNREACHABLE",
  "DENIED",
  "TOSSUP_ABSENT",
  "TOSSUP_NURTURING_A",
  "TOSSUP_NURTURING_B",
  "TOSSUP_NURTURING_C",

  "AUTOMATED",
  "IMPOSSIBLE",
  "DENIED_ON_AI",
  "AI_NURTURING",
  "SIMULTANEOUS_TOSSUP",
  "TOSSUP_NOT_REGISTERED",

  // "OTHERS"
] as const;

type CallStatsFilter = {
  period: CallStatsPeriod;
  commodityId?: string;
  scriptId?: string;
  callListId?: string;
  daysOfWeek?: string[];
  showingCallResults?: Array<(typeof showingCallResultOptions)[number]>;
};

const getPeriodBoundaries = (period: CallStatsPeriod) => {
  const now = new Date();
  const year = now.getFullYear();
  const monthIndex = now.getMonth();
  const day = now.getDate();
  const dayOfWeek = now.getDay();
  const mondayOffset = (dayOfWeek + 6) % 7; // 月曜日を基準にする

  switch (period) {
    case "THIS_YEAR":
      return [new Date(year, 0), new Date(year, 11, 31, 23, 59, 59, 999)];
    case "THIS_MONTH":
      return [new Date(year, monthIndex, 1), new Date(year, monthIndex + 1, 0)];
    case "LAST_MONTH":
      return [new Date(year, monthIndex - 1, 1), new Date(year, monthIndex, 0)];
    case "THIS_WEEK":
      return [
        new Date(year, monthIndex, day - mondayOffset),
        new Date(year, monthIndex, day - mondayOffset + 6, 23, 59, 59, 999),
      ];
    case "LAST_WEEK":
      return [
        new Date(year, monthIndex, day - mondayOffset - 7),
        new Date(year, monthIndex, day - mondayOffset - 1, 23, 59, 59, 999),
      ];
    case "TODAY":
      return [
        new Date(year, monthIndex, day),
        new Date(year, monthIndex, day),
      ];
    default:
      throw new Error("Invalid period: " + period);
  }
};

const getDailyCallSummaries = async ({
  period,
  scriptId,
  callListId,
  daysOfWeek,
}: CallStatsFilter) => {
  const [startDate, endDate] = getPeriodBoundaries(period);
  const daysOfWeekStr = (daysOfWeek:string[]) =>{
    const daysOfWeekArr: Array<"Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday"> = []
    if(!daysOfWeek) return null;
    for(const day of daysOfWeek){
      switch(day){
        case "MONDAY":
          daysOfWeekArr.push("Monday");
          break;
        case "TUESDAY":
          daysOfWeekArr.push("Tuesday");
          break;
        case "WEDNESDAY":
          daysOfWeekArr.push("Wednesday");
          break;
        case "THURSDAY":
          daysOfWeekArr.push("Thursday");
          break;
        case "FRIDAY":
          daysOfWeekArr.push("Friday");
          break;
        case "SATURDAY":
          daysOfWeekArr.push("Saturday");
          break;
        case "SUNDAY":
          daysOfWeekArr.push("Sunday");
          break;
      }
    }
    if(daysOfWeekArr.length === 0) return null;
    return daysOfWeekArr;
  }

  const res = await request({
    path: "/calls/summaries/daily",
    httpMethod: "post",
    params: {
      body: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        scriptIds: scriptId ? [scriptId] : null,
        contactListIds: callListId ? [callListId] : null,
        weekdays: daysOfWeekStr(daysOfWeek),
      },
    },
  });
  return {
    startDate,
    endDate,
    summaries: res.data.summaries,
  };
};

export {
  getCallStats,
  callStatsPeriodLabels,
  getDailyCallSummaries,
  callStatsPeriods,
  showingCallResultOptions,
};
export type { CallStats, CallStatsFilter, CallStatsPeriod };
