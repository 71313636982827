/* NotFound.js */

import { Container } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container>
      <>
        <h1>お探しのページは見つかりませんでした。</h1>
        <div>
          <Link to={`/`}>ホームに戻る</Link>
        </div>
      </>
    </Container>
  );
};

export default NotFound;
