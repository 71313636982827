import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import commonReducer from "./commonSlice";
import callSlice from "./callSlice";
import callStatsSlice from "./callStatsSlice";
import companySlice from "./companySlice";
import { telaiSocketMiddleware } from "./telaiSocketMiddleware";

const rootReducer = combineReducers({
  user: userReducer,
  call: callSlice,
  callStats: callStatsSlice,
  common: commonReducer,
  company: companySlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(telaiSocketMiddleware(import.meta.env.VITE_TELAI_SOCKET_URL)),   
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export default store;
