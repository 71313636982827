import {
  getRedialCalls,
  getAINurturingContactList,
  getAINurturingList
} from "@/features/Redial/GetRedialCalls";
import {
  updateAiRedialCalls,
  updateManualRedialCalls,
  upDateAiRedialCallList
} from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useCompanyConfig } from "./useCompanyConfig";
import { CallResult, callResults } from "@/models/CallHistory";
import { RedialContact } from "@/models/Call";
const DEFAULT_REDIAL_INTERVAL_DAYS = 5;
const DEFAULT_REDIAL_MAX_ATTEMPTS = 3;

let fetching = false;

export const useRedialCalls = ({
  disableLoading = false,
}: {
  disableLoading?: boolean;
} = {}) => {
  const {
    tenantName,
    callResultsToAiRedial,
    callResultsToManualRedial,
    redialIntervalDays,
    redialAttempts: redialMaxAttempts,
  } = useCompanyConfig();
  const isSignedIn = useSelector(
    (state: RootState) => state.user.loggedInUser.online,
  );
  const manualRedialCalls = useSelector(
    (state: RootState) => state.call.manualRedialCalls,
  );
  
  const aiRedialCalls = useSelector(
    (state: RootState) => state.call.aiRedialCalls,
  );

  const aiRedialCallList = useSelector(
    (state: RootState) => state.call.aiRedialCallList,
  );

  const dispatch = useDispatch<AppDispatch>();

  const internalFetchRedialCalls = useCallback(async () => {
    if (!isSignedIn || fetching || !tenantName) return;
    fetching = true;

    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));

    try {
      // 再架電間隔と再架電最大回数 (初期値は5日間隔で3回)
      const [manual, ai] = await Promise.all([
        getRedialCalls({
          callResultsToRedial: callResultsToManualRedial as CallResult[],
          redialMaxAttempts: {
            ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
            ...(redialMaxAttempts as unknown as Record<CallResult, number>),
          },
        }),
        getAINurturingList({
          callResultsToRedial: callResultsToAiRedial as CallResult[],
        }),
      ]);
      dispatch(updateManualRedialCalls(manual));
      dispatch(upDateAiRedialCallList(ai));

      console.log("Fetched redial calls successfully", manual, ai);
    } catch (error) {
      console.error("Error fetching redial calls:", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "fetchRedialCalls", state: false }));
    }

    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
    fetching = false;
  }, [
    dispatch,
    isSignedIn,
    disableLoading,
    tenantName,
    callResultsToManualRedial,
    redialMaxAttempts,
    callResultsToAiRedial,
    redialIntervalDays
  ]);


  const fetchManualRedialCalls = useCallback(async (limit:number=10, offset:number=0, sortField: string, sort:"asc"|"desc" ) => {
    if (!isSignedIn || fetching || !tenantName) return;
    fetching = true;
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));
  
    try {
      const manual = await getRedialCalls({
        callResultsToRedial: callResultsToManualRedial as CallResult[],
        redialMaxAttempts: {
          ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
          ...(redialMaxAttempts as unknown as Record<CallResult, number>),
        },
        nextCallScheduledAtStart: undefined,
        nextCallScheduledAtEnd: undefined,
        limit: limit,
        offset: offset,
        orderBy: sortField,
        order: sort
      });
      dispatch(updateManualRedialCalls(manual));
      console.log("Fetched manual redial calls successfully", manual);
    } catch (error) {
      console.error("Error fetching manual redial calls:", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "fetchManualRedialCalls", state: false }));
    }
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
    fetching = false;
  }, [
    dispatch,
    isSignedIn,
    disableLoading,
    tenantName,
    callResultsToManualRedial,
    redialMaxAttempts
  ]);

  // ただコールリストをfetchして返す関数
  const fetchAINurturingContactList = async (contactListId:string, scriptId:string, limit:number=10, offset:number=0, sortField: string, sort:"asc"|"desc" ):Promise<RedialContact> => {

    fetching = true;
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));
  
    try {
      const ai = await getAINurturingContactList({
        callResultsToRedial: callResultsToAiRedial as CallResult[],
        redialIntervalDays: {
          ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_INTERVAL_DAYS])),
          ...redialIntervalDays,
        },
        redialMaxAttempts: {
          ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
          ...(redialMaxAttempts as unknown as Record<CallResult, number>),
        },
        limit,
        offset,
        listId: contactListId,
        latestCallScriptId: scriptId,
        orderBy: sortField,
        order: sort
      });
      return ai;
    } catch (error) {
      console.error("Error fetching AI redial calls:", error);
    } finally {
        
      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
        fetching = false;
    }

  }

    
    


  const fetchAiRedialCalls = useCallback(async (limit:number=10, offset:number=0, listId:string, latestCallScriptId:string ) => {
    if (!isSignedIn || fetching || !tenantName) return;
    fetching = true;
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));
  
    try {
      const ai = await getAINurturingContactList({
        callResultsToRedial: callResultsToAiRedial as CallResult[],
        redialIntervalDays: {
          ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_INTERVAL_DAYS])),
          ...redialIntervalDays,
        },
        redialMaxAttempts: {
          ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
          ...(redialMaxAttempts as unknown as Record<CallResult, number>),
        },
        limit,
        offset,
        listId: listId,
        latestCallScriptId: latestCallScriptId
      });
      dispatch(updateAiRedialCalls(ai));
      console.log("Fetched AI redial calls successfully", ai);
    } catch (error) {
      console.error("Error fetching AI redial calls:", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "fetchAiRedialCalls", state: false }));
    }
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
    fetching = false;
  }, [
    dispatch,
    isSignedIn,
    disableLoading,
    tenantName,
    callResultsToAiRedial,
    redialIntervalDays,
    redialMaxAttempts
  ]);

  const fetchAINurturingList = useCallback(async (limit:number=10, offset:number=0 ) => {
    if (!isSignedIn || fetching || !tenantName) return;
    fetching = true;
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));
  
    try {
      const aiNurturingList = await getAINurturingList({
        callResultsToRedial: callResultsToAiRedial as CallResult[],
        limit: limit,
        offset: offset,
      });
      dispatch(upDateAiRedialCallList(aiNurturingList));
      console.log("Fetched AI redial calls successfully", aiNurturingList);
    } catch (error) {
      console.error("Error fetching AI redial calls:", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "fetchAiRedialCalls", state: false }));
    }
  
    if (!disableLoading)
      dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
    fetching = false;
  }
  , [
    dispatch,
    isSignedIn,
    disableLoading,
    tenantName,
    callResultsToAiRedial,
    redialIntervalDays,
    redialMaxAttempts
  ]);
  

  useEffect(() => {
    internalFetchRedialCalls();
  }, [internalFetchRedialCalls]);


  return { manualRedialCalls, aiRedialCalls,aiRedialCallList, internalFetch: internalFetchRedialCalls, fetchManualRedialCalls, fetchAiRedialCalls,fetchAINurturingList,fetchAINurturingContactList };
};
