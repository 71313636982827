import { AppDispatch, RootState } from "@/store/store";
import { getUsers } from "@/store/userSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

let fetched = false;

export const useCompanyUsers = (uids: string[] = []) => {
  const user = useSelector((state: RootState) => state.user);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      if (fetched) return;
      if (!isSignedIn) {
        fetched = false;
        return;
      }
      dispatch(getUsers());
      fetched = true;
    };
    fetch();
  }, [dispatch, isSignedIn, uids]);

  return user.companyUsers;
};
