import { getCallListMetadata } from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let fetching = false;

export const useCallListMetadata = ({
  disableLoading = false,
}: { disableLoading?: boolean } = {}) => {
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const callListMetadata = useSelector(
    (state: RootState) => state.call.callListMetadata,
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn || fetching) return;
      fetching = true;
      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCallList", state: true }));
      await dispatch(getCallListMetadata(user.tenantId));
      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCallList", state: false }));
      fetching = false;
    };
    fetch();
  }, [dispatch, isSignedIn]);
  return callListMetadata;
};
