import { CallStats } from "@/google/callStats";
import { ShowChartOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { showingCallResultOptions } from "@/google/callStats";

type Props = {
  data: CallStats[];
  xAxis: string[];
};

const CallStatsLineChart = ({ data, xAxis }: Props) => {
  return (
    <Box position="relative" width="100%">
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ py: 1, px: 2, mb: -5.5 }}
        bgcolor="#f3f3f3"
      >
        <ShowChartOutlined></ShowChartOutlined>
        <Typography>コール結果推移</Typography>
      </Stack>
      <LineChart
        xAxis={[
          {
            data: Array.from({ length: data.length }).map((_, k) => k),
            valueFormatter: (v) => xAxis[v],
            tickInterval: Array.from({ length: data.length }).map((_, k) => k),
          },
        ]}
        series={[
          {
            curve: "linear",
            data: data.map(
              (stats) =>
                showingCallResultOptions.reduce(
                  (acc, key) => acc + (stats[key] ?? 0),
                  0,
                ) -
                stats.UNREACHABLE -
                stats.AUTOMATED,
            ),
            label: "接続数",
          },
          {
            curve: "linear",
            data: data.map(
              (stats) =>
                stats.TOSSUP_ABSENT +
                stats.TOSSUP_APPOINTMENT +
                stats.TOSSUP_NURTURING_A +
                stats.TOSSUP_NURTURING_B +
                stats.TOSSUP_NURTURING_C +
                stats.DENIED,
            ),
            label: "トスアップ数",
          },
          {
            curve: "linear",
            data: data.map((stats) => stats.TOSSUP_APPOINTMENT),
            label: "アポ獲得件数",
          },
        ]}
        height={350}
        grid={{ horizontal: true }}
        margin={{ top: 80, bottom: 30, right: 54, left: 54 }}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: "0.8rem",
            },
            position: {
              vertical: "top",
              horizontal: "right",
            },
            padding: {
              top: 50,
              right: 12,
            },
            itemMarkWidth: 12,
            itemMarkHeight: 12,
          },
        }}
        sx={{
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-12px)",
          },
          // [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 2 },
        }}
      ></LineChart>
    </Box>
  );
};

export default CallStatsLineChart;
