import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "../google/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCurrentUser,
  initUserSlice,
  resetUserState,
} from "@/store/userSlice";
import { AppDispatch } from "@/store/store";
import { initCallSlice } from "@/store/callSlice";
import { initCallStatsSlice } from "@/store/callStatsSlice";
import { initCompanySlice } from "@/store/companySlice";
import { request } from "@/models/telai-backend/client";

let fetched = false;
let tenantId: string;

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      console.debug("onAuthStateChanged: " + (user?.tenantId || ""));
      if (user && user.uid) {
        tenantId = user.tenantId;

        if (
          location.pathname === `/${tenantId}/signin` ||
          location.pathname === "/"
        )
          navigate(`/${user.tenantId}/`);

        if (!fetched) {
          fetched = true;
          const res = await request({
            path: "/users/me",
            httpMethod: "get",
          });

          if (!res.data) {
            console.error("Failed to fetch user data");
            return;
          }

          await dispatch(getCurrentUser());
        }
      } else {
        fetched = false;
        dispatch(initCallStatsSlice());
        dispatch(initCallSlice());
        dispatch(initUserSlice());
        dispatch(initCompanySlice());
        dispatch(resetUserState()); // firestoreのユーザー状態を変更するのはログイン中のみ可能なためauth.ts側で変更
        if (tenantId && !location.pathname.endsWith("/signin"))
          navigate(`/${tenantId}/signin`);
        else if (
          location.pathname !== "/" &&
          !location.pathname.endsWith("/signin")
        )
          navigate("/");
        tenantId = "";
      }
    });
  }, [dispatch, location, navigate, tenantId]);
};
