// x-x (範囲) と x (単体) の組み合わせで範囲指定
// 範囲を結合した後の文字列として mergedString を返す
export const parseCallRangeString = (
  value: string,
):
  | {
      min: number;
      max: number;
      selection: number[];
    }
  | false => {
  const elements = value.split(",");
  let min = Infinity;
  let max = -1;
  let selection: number[] = [];

  if (elements.length > 1) return false;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (!element) continue;
    if (element.match(/^\d+-\d+$/)) {
      const [start, end] = element.split("-").map(Number);
      if (end < start) return false;
      selection = selection.concat(
        Array.from({ length: end - start + 1 }, (v, k) => start + k),
      );
      min = start < min ? start : min;
      max = max < end ? end : max;
      continue;
    }
    // if(element.match(/^\d+$/)) {
    //   const num = Number(element)
    //   selection.push(num)
    //   min = num < min ? num : min
    //   max = max < num ? num : max
    //   continue
    // }
    return false;
  }
  selection = Array.from(new Set(selection));

  return {
    min,
    max,
    selection,
  };
};

export const convertToRangeString = (numbers: number[]): string => {
  if (numbers.length === 0) {
    return "";
  }

  const ranges: string[] = [];
  let start = numbers[0];
  let prev = start;

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] !== prev + 1) {
      ranges.push(start === prev ? `${start}` : `${start}-${prev}`);
      start = numbers[i];
    }
    prev = numbers[i];
  }

  ranges.push(start === prev ? `${start}` : `${start}-${prev}`);

  return ranges.join(",");
};
