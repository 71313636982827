import { auth } from "@/google/auth";
import { isValidEmail } from "@/utils/isValidEmail";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { sendPasswordResetEmail } from "firebase/auth";
import { useEffect, useState } from "react";

const PasswordResetDialog = ({ open, onCancel, onSubmit }: {
  open: boolean,
  onCancel: () => void,
  onSubmit: () => void
}) => {
  const [email, setEmail] = useState("");
  const [helperText, setHelperText] = useState("");
  const onSubmitEmail = () => {
    sendPasswordResetEmail(auth, email);
    onSubmit();
  }

  useEffect(() => {
    if (email && !isValidEmail(email))
      setHelperText("メールアドレスの形式で入力してください");
    else setHelperText("");
  }, [email]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        パスワードの再設定
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 4 }}>
          登録されているメールアドレスへ、パスワード再設定メールを送信します。<br></br>
          メール内容をご確認の上、新しいパスワードを登録してください。
        </Typography>
        <TextField
          size="small"
          label="メールアドレス"
          type="email"
          fullWidth
          value={email}
          error={Boolean(helperText)}
          helperText={helperText}
          onChange={(e) => setEmail(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>キャンセル</Button>
        <Button onClick={onSubmitEmail} variant="contained">送信</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PasswordResetDialog;