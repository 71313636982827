import { useState, useMemo, useEffect, useCallback } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { PhoneOutlined, Backspace } from "@mui/icons-material";
import formatJapanesePhoneNumber from "@/utils/formatJapanesePhoneNumber";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { useDispatch } from "react-redux";
import { setSnackbar } from "@/store/commonSlice";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { request } from "@/models/telai-backend/client";
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";
import { setUserStatus } from "@/store/userSlice";

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, "backspace", 0];

const ManualCall = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const companyPhoneNumbers = useCompanyPhoneNumbers();
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );
  const uid = useSelector((state: RootState) => state.user.loggedInUser.id);
  const dispatch = useDispatch<AppDispatch>();
  const users = useCompanyUsers();
  const navigate = useNavigate();
  const location = useLocation();
  const { tenantId } = useParams();
  const breadcrumbRefs = useMemo(() => [{ title: "手動コール" }], []);

  const handleNumberClick = (num) => {
    if (phoneNumber.length < 15) {
      setPhoneNumber((prevNum) => prevNum + num);
    }
  };

  const handleBackspace = () => {
    setPhoneNumber((prevNum) => prevNum.slice(0, -1));
  };

  // NOT_ASSIGNEDでない場合はホーム画面へ遷移 (同時に通話が発生することを避けるため)
  useEffect(() => {
    if (users[uid] && users[uid].status !== "FREE") {
      navigate(`/${tenantId}`);
    }
  }, [users, uid]);

  useEffect(() => {
    const formatted = formatJapanesePhoneNumber(phoneNumber);
    if (formatted === false) {
      setFormattedPhoneNumber(phoneNumber);
      return;
    }
    setFormattedPhoneNumber(formatted);
  }, [phoneNumber]);

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const initialPhoneNumber = q.get("p");
    const prevCallSids = (q.get("ids") || "").split(",");

    if (initialPhoneNumber) setPhoneNumber(initialPhoneNumber);
    console.log(prevCallSids);
  }, [location]);

  const handleStartCall = useCallback(async () => {
    if (phoneNumber) {
      try {
        const normalizedPhoneNumber = phoneNumber.replace(/^0/, "+81");
        const res = await request({
          path: "/calls",
          httpMethod: "post",
          params: {
            body: {
              from: companyPhoneNumbers[0],
              to: normalizedPhoneNumber,
              scriptId: null,
            },
          },
        });
        console.log({ res });

        if(res.result === "success") {
          console.log("発信成功");
          // ユーザーのステータスを更新
          dispatch(setUserStatus({
            status: "IN_CALL",
          }));
          console.log("move to callscreen (default)");
          navigate(`/${tenantId}/callscreen`);
        }
      } catch (error) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "発信に失敗しました。",
          }),
        );
      }
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "電話番号を入力してください。",
        }),
      );
    }
  }, [phoneNumber, companyId, uid]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            bgcolor: "#D3D3D3",
            p: 6,
            pt: 8,
            mx: "auto",
            borderRadius: 2,
            width: 300,
          }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: 4,
              ml: -5,
              mr: -5,
              wordBreak: "break-all",
            }}
            color={"#333"}
          >
            {formattedPhoneNumber || "　"}
          </Typography>
          <Grid container spacing={2}>
            {buttons.map((btn, index) => (
              <Grid item xs={4} key={index}>
                {btn === "backspace" ? (
                  <Button
                    variant="contained"
                    onClick={handleBackspace}
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      height: 60,
                      bgcolor: "#808080",
                      "&:hover": {
                        bgcolor: "#707070",
                      },
                      borderRadius: "50%",
                    }}
                  >
                    <Backspace />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleNumberClick(btn)}
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      height: 60,
                      fontSize: 24,
                      bgcolor: "#808080",
                      "&:hover": {
                        bgcolor: "#707070",
                      },
                      borderRadius: "50%",
                    }}
                  >
                    {btn}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              height: 48,
              bgcolor: "#FFA07A",
              "&:hover": {
                bgcolor: "#FF8C61",
              },
              borderRadius: 10,
              width: 100,
            }}
            onClick={handleStartCall}
          >
            <PhoneOutlined fontSize="large" />
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ManualCall;
