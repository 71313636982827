import { components } from "./telai-backend/schema";

export enum BATCH_STATUS {
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Aborting = "ABORTING",
  Aborted = "ABORTED",
}

export type CallBatch = components["schemas"]["CallBatch"];
