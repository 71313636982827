import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";

const formatTimestamp = (timestamp: Timestamp | string | Date | undefined) => {
  // 0001-01-01T00:00:00Zの場合、空文字を返す
  if (timestamp === "0001-01-01T00:00:00Z") return "";


  if (typeof timestamp === "string") {
    // 引数が文字列の場合、文字列を解析して日付オブジェクトを生成する
    try {
      return dayjs(timestamp).format("YYYY-MM-DD");
    } catch (e) {
      return "";
    }
  }

  if (typeof timestamp === "object" && timestamp instanceof Date) {
    return dayjs(timestamp).format("YYYY-MM-DD");
  }
  if (!timestamp) return "";
  return dayjs(timestamp.toDate()).format("YYYY-MM-DD");
};

export default formatTimestamp;
