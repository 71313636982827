import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { Script } from "@/models/Script";
import formatTimestamp from "@/utils/formatTimestamp";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export const ScriptCard = ({
  script,
  scriptId,
}: {
  script: Script;
  scriptId: string;
}) => {
  const users = useCompanyUsers([script.creatorId]);
  const navigate = useNavigate();
  const { tenantId } = useParams();

  /**
   * スクリプトカードを非表示にするかどうか
   * - スクリプトIDの最初がwから始まる場合は非表示
   * @param {string} scriptId スクリプトID
   * @returns {boolean} true: 非表示, false: 表示
   */
  const hiddenScriptCard = (scriptId: string) => scriptId.charAt(0) === "w";

  if (hiddenScriptCard(scriptId)) {
    return null;
  }

  return (
    <Paper sx={{ py: 2, px: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack>
            <Typography textAlign="left">スクリプト名</Typography>
            <Typography
              textAlign="left"
              variant="h6"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {script.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Table
            size="small"
            sx={{ "& .MuiTableCell-root": { border: "none", py: 0 } }}
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "right", px: 0, width: 80 }}>
                  コール先:
                </TableCell>
                <TableCell sx={{ pl: 2, pr: 0 }}>
                  {script.companyName || "(不明)"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: "right", px: 0 }}>
                  作成日:
                </TableCell>
                <TableCell sx={{ pl: 2, pr: 0 }}>
                  {formatTimestamp(script.createdAt) || "(不明)"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: "right", px: 0 }}>
                  作成者:
                </TableCell>
                <TableCell sx={{ pl: 2, pr: 0 }}>
                  {users[script.creatorId]?.name || "(不明)"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={4}>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={1}
            alignItems="center"
            height="100%"
          >
            <Button
              variant="contained"
              onClick={() => navigate(`/${tenantId}/script/${scriptId}`)}
            >
              詳細
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
