import { deleteCallList } from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { DeleteOutline, ErrorOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fade,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  name: string;
  memo: string;
  createdAt: string;
  creator: string;
  id: string;
  searchWord: string;
};

const CallListCard = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { tenantId } = useParams();
  const handleClick = useCallback(() => {
    navigate(`/${tenantId}/calllist/${props.id}`);
  }, [navigate, props.id]);

  const handleDeleteClick = useCallback(async () => {
    dispatch(setLoadingBackdrop({ key: "deleteCallList", state: true }));
    await dispatch(deleteCallList({ id: props.id }));
    dispatch(setLoadingBackdrop({ key: "deleteCallList", state: false }));
  }, [dispatch, user, props.id]);

  return (
    <Card sx={{ position: "relative" }}>
      <CardContent sx={{ textAlign: "left", px: 6, py: 3 }}>
        <Typography sx={{ fontSize: "0.8rem" }}>コールリスト名</Typography>
        <Typography variant="h5">{props.name}</Typography>
        <Stack gap={1} mt={2}>
          <Typography>メモ: {props.memo || "なし"}</Typography>
          <Typography>検索ワード: {props.searchWord || "なし"}</Typography>
          <Stack direction="row" gap={2}>
            <Typography>作成日: {props.createdAt || "(不明)"}</Typography>
            <Typography>作成者: {props.creator || "(不明)"}</Typography>
          </Stack>
        </Stack>
      </CardContent>
      <Divider></Divider>
      <CardActions
        sx={{ pr: 4, pl: 6, py: 2, justifyContent: "space-between" }}
      >
        <Button color="primary" variant="contained" onClick={handleClick}>
          詳細・編集
        </Button>
        <IconButton
          color="error"
          size="large"
          onClick={() => setDeleteConfirm(true)}
        >
          <DeleteOutline></DeleteOutline>
        </IconButton>
      </CardActions>
      <Fade in={deleteConfirm}>
        <Stack
          sx={{
            position: "absolute",
            background: "rgba(0, 0, 0, 0.5)",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          justifyContent="center"
        >
          <Paper sx={{ p: 4, mx: "auto", bgcolor: "#fff" }}>
            <Stack>
              <Box display="flex" gap={1}>
                <ErrorOutlineOutlined />
                <Typography fontWeight="bold">
                  このコールリストを削除しますか？
                </Typography>
              </Box>

              <Box display="flex" mt={2} gap={2} justifyContent="center">
                <Button
                  variant="outlined"
                  onClick={() => setDeleteConfirm(false)}
                >
                  キャンセル
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteClick}
                >
                  削除
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Fade>
    </Card>
  );
};

export default CallListCard;
