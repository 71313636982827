import { callResultLabels } from "@/models/CallHistory";
import { CompanySettings } from "@/models/CompanySettings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CompanyState = {
  config: CompanySettings;
  phoneNumbers: string[];
};

const initialState: CompanyState = {
  config: {
    tenantName: "",
    redialAttempts: 3,
    redialMaxAttempts: {},
    redialIntervalDays: {},
    callResultsToAiRedial: ["UNREACHABLE"],
    callResultsToManualRedial: [
      "AI_NURTURING",
      "SIMULTANEOUS_TOSSUP",
      "TOSSUP_NURTURING_A",
      "TOSSUP_NURTURING_B",
      "TOSSUP_NURTURING_C",
      "TOSSUP_ABSENT",
    ],
    customCallResultLabels: {
      ...callResultLabels,
    },
  },
  phoneNumbers: [],
};

const companySlice = createSlice({
  name: "callStats",
  initialState,
  reducers: {
    initCompanySlice: (state) => {
      Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
        state[key] = value;
      });
      console.debug("company slice initialized");
    },
    setCompanyConfig: (state, action: PayloadAction<CompanySettings>) => {
      state.config = {
        ...state.config,
        ...action.payload,
        customCallResultLabels: {
          ...state.config.customCallResultLabels,
          ...action.payload.customCallResultLabels,
        },
      };
    },
    setCompanyPhoneNumbers: (state, action: PayloadAction<string[]>) => {
      state.phoneNumbers = [...action.payload];
    },
  },
});

export const { initCompanySlice, setCompanyConfig, setCompanyPhoneNumbers } =
  companySlice.actions;
export default companySlice.reducer;
