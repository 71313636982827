import RelativeBackdrop from "@/components/RelativeBackdrop";
import CallProcessPlaceHolder from "@/features/CallProcess/CallProcessPlaceHolder";
import CallStatsLineChart from "@/features/CallStats/CallStatsLineChart";
import CallStatsPieChart from "@/features/CallStats/CallStatsPieChart";
import CallStatsPieChartLegend from "@/features/CallStats/CallStatsPieChartLegend";
import { CallStatsFilter, showingCallResultOptions } from "@/google/callStats";
import { useCallProcesses } from "@/hooks/useCallProcesses";
import { useCallStatsByInterval } from "@/hooks/useCallStatsByInterval";
import { useCompanyConfig } from "@/hooks/useCompanyConfig";
import { CallBatch } from "@/models/CallBatch";
import { CallResult } from "@/models/CallHistory";
import { setCompanyConfig } from "@/store/companySlice";
import { RootState } from "@/store/store";
import {
  Box,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CallProcessCard from "../features/CallProcess/CallProcessCard";
import CallProcessLogFilter from "../features/CallProcess/CallProcessLogFilter";
import CallResultSummaryLarge from "../features/CallProcess/CallResultSummaryLarge";
import Carousel from "../features/carousel/CarouselRepeat";

type CallStatsSummary = { [key in CallResult]?: number };

const Home = () => {
  const callResultLabels = useSelector(
    (state: RootState) => state.company.config.customCallResultLabels,
  );
  const uid = useSelector((state: RootState) => state.user.loggedInUser.id);
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const callProcesses = useCallProcesses();
  const settings = useCompanyConfig();

  //setting fetch
  useEffect(() => {
    setCompanyConfig(settings);
  }, [settings]);

  const shownCallProcess: Record<string, CallBatch> = useMemo(
    () =>
      Object.entries(callProcesses)
        .filter(([, process]) =>
          ["INITIALIZING", "RUNNING", "ABORTING", "IN_PROGRESS"].includes(
            process.status,
          ),
        )
        .reduce((prev, [docId, process]) => {
          prev[docId] = process;
          return prev;
        }, {}),
    [callProcesses],
  );

  const CallProcessesSorted = useMemo(() => {
    // 自分が割り当てられたプロセスを先頭に持ってきたオブジェクト
    if (!shownCallProcess) return {};

    const assignedProcessId = Object.keys(shownCallProcess).find((docId) =>
      user.assignedCallBatchIds.includes(docId),
    );
    if (assignedProcessId) {
      const others = { ...shownCallProcess };
      delete others[assignedProcessId];
      return {
        [assignedProcessId]: shownCallProcess[assignedProcessId],
        ...others,
      };
    }
    return shownCallProcess;
  }, [shownCallProcess, uid]);
  const [filter, setFilter] = useState<CallStatsFilter>({
    period: "TODAY",
    showingCallResults: [...showingCallResultOptions],
  });
  const [statsSum, setStatsSum] = useState<
    (CallStatsSummary & { TOTAL: number }) | null
  >(null);
  const [stats, fetching] = useCallStatsByInterval(filter);

  const { answerCount, tossUpCount, appointmentCount } = useMemo(() => {
    if (!statsSum)
      return { answerCount: 0, tossUpCount: 0, appointmentCount: 0 };
    return {
      answerCount: statsSum.TOTAL - statsSum.UNREACHABLE - statsSum.AUTOMATED,
      tossUpCount:
        statsSum.TOSSUP_ABSENT +
        statsSum.TOSSUP_APPOINTMENT +
        statsSum.DENIED +
        statsSum.TOSSUP_NURTURING_A +
        statsSum.TOSSUP_NURTURING_B +
        statsSum.TOSSUP_NURTURING_C,
      appointmentCount: statsSum.TOSSUP_APPOINTMENT,
    };
  }, [statsSum]);

  const pieChartData = useMemo(() => {
    const sum: CallStatsSummary & {
      TOTAL: number;
      // OTHERS: number
    } = {
      TOTAL: 0,
      // OTHERS: 0
    };
    showingCallResultOptions.forEach((key) => (sum[key] = 0));
    console.log(stats);
    Object.values(stats).forEach((data) => {
      sum.TOTAL += data.TOTAL ?? 0; // 非表示の項目も含めた総コール数
      // 表示している項目
      showingCallResultOptions.forEach((key) => (sum[key] += data[key] ?? 0));
      // 非表示の項目の合計 (手動架電/受電等)
      // sum.OTHERS += data.TOTAL - showingCallResultOptions.reduce((acc, key) => acc + (data[key] ?? 0), 0)
    });

    // ここでTOTALを再計算している手動架電,受電等を除外するため
    sum.TOTAL = showingCallResultOptions.reduce(
      (acc, key) => acc + (sum[key] ?? 0),
      0,
    );
    setStatsSum(sum);
    console.debug("Totalを再計算しています", sum.TOTAL);
    return Object.entries(sum).map(([key, value]) => ({
      id: key,
      value,
      label: callResultLabels[key],
    }));
  }, [stats]);

  // 正規料金課金コール数=総コール数-不通
  const connectionRingData = useMemo(() => {
    return [
      {
        id: "CONNECTION",
        label: "接続",
        value:
          statsSum?.TOTAL - statsSum?.AUTOMATED - statsSum?.UNREACHABLE || 0,
      },
      {
        id: "NO_RESPONSE",
        label: "不通",
        value: statsSum?.AUTOMATED + statsSum?.UNREACHABLE || 0,
      },
    ];
  }, [statsSum]);

  const tossUpRingData = useMemo(() => {
    //undefinedが紛れ込んでいたため
    const tossUpKeys = [
      "TOSSUP_APPOINTMENT",
      "TOSSUP_ABSENT",
      "DENIED",
      "TOSSUP_NURTURING_A",
      "TOSSUP_NURTURING_B",
      "TOSSUP_NURTURING_C",
    ];
    const tossUpCount = tossUpKeys.reduce(
      (sum, key) => sum + (statsSum?.[key] || 0),
      0,
    );

    return [
      {
        id: "TOSSUP",
        label: "トスアップ",
        value: tossUpCount || 0,
      },
      {
        id: "NO_RESPONSE",
        label: "AI対応",
        value:
          statsSum?.TOTAL - statsSum?.UNREACHABLE - statsSum?.AUTOMATED || 0,
      },
    ];
  }, [statsSum]);

  const handleFilterApply = (newFilter: CallStatsFilter) => {
    setFilter({
      ...newFilter,
    });
  };

  useEffect(() => {
    setFilter({ ...filter });
  }, [companyId]);

  return (
    <Stack
      sx={{ width: "100%" }}
      position="relative"
      width="100%"
      bgcolor="#fbfbfb"
    >
      <Box
        bgcolor="#E1E4E8"
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "row",
          alighItems: "center",
          alighContent: "center",
          justifyContent: "center",
          pt: 3,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ overflow: "visible" }}>
            <Carousel
              cards={
                Object.entries(CallProcessesSorted).length
                  ? Object.entries(CallProcessesSorted).map((process, i) => (
                      <CallProcessCard callProcess={process[1]} key={i} />
                    ))
                  : [<CallProcessPlaceHolder key={-1} />]
              }
              cardsPerPage={2}
              spacing={3}
            />
          </Box>
        </Stack>
      </Box>

      <Stack
        direction="row"
        justifyContent="center"
        pt={4}
        pb={8}
        sx={{ px: 8 }}
        gap={4}
        maxWidth={1200}
        mx="auto"
      >
        <Stack flexGrow={1} gap={4} width={760}>
          <Box sx={{ position: "relative" }}>
            {fetching ? (
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: 350 }}
              ></Skeleton>
            ) : (
              <Stack direction="row" maxWidth={760} gap={2}>
                <Paper sx={{ width: "100%" }}>
                  <CallStatsLineChart
                    xAxis={Object.keys(stats)
                      .map((key) => {
                        const [year, month, day] = key.split("-");
                        const formattedMonth = String(parseInt(month, 10)).padStart(2, '0');
                        const formattedDay = String(parseInt(day, 10)).padStart(2, '0');
                        if(filter.period === "THIS_YEAR") {
                          return `${year}/${formattedMonth}`;
                        }
                        return `${year}/${formattedMonth}/${formattedDay}`;
                      })}
                    data={Object.values(stats)}
                  ></CallStatsLineChart>
                </Paper>
                <Stack justifyContent="center" gap={2}>
                  <Paper>
                    <Stack gap={1} p={2}>
                      <Box
                        px={2}
                        py={1}
                        mx={-2}
                        mt={-2}
                        mb={1}
                        bgcolor="#f3f3f3"
                      >
                        <Typography fontSize="0.9rem">接続率</Typography>
                      </Box>

                      <Box position="relative">
                        <PieChart
                          height={80}
                          width={80}
                          colors={["rgb(2, 178, 175)", "#cccccc"]}
                          series={[
                            {
                              data: connectionRingData,
                              innerRadius: 30,
                              outerRadius: 40,
                              paddingAngle: 5,
                              cornerRadius: 5,
                            },
                          ]}
                          slots={{ legend: () => <></> }}
                          margin={{ left: 0, right: 0 }}
                        />
                        <Typography
                          position="absolute"
                          sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          {(
                            (connectionRingData[0].value /
                              (connectionRingData[0].value +
                                connectionRingData[1].value)) *
                            100
                          ).toFixed(0)}
                          %
                        </Typography>
                      </Box>
                      <Typography fontSize="0.85rem" sx={{ mt: -1 }}>
                        {connectionRingData[0].value} /{" "}
                        {connectionRingData[0].value +
                          connectionRingData[1].value}
                      </Typography>
                    </Stack>
                  </Paper>

                  <Paper>
                    <Stack gap={1} p={2}>
                      <Box
                        px={1}
                        py={1}
                        mx={-2}
                        mt={-2}
                        mb={1}
                        bgcolor="#f3f3f3"
                      >
                        <Typography fontSize="0.9rem">トスアップ率</Typography>
                      </Box>
                      <Box position="relative">
                        <PieChart
                          height={80}
                          width={80}
                          colors={["rgb(46, 150, 255)", "#cccccc"]}
                          series={[
                            {
                              data: tossUpRingData,
                              innerRadius: 30,
                              outerRadius: 40,
                              paddingAngle: 5,
                              cornerRadius: 5,
                            },
                          ]}
                          slots={{ legend: () => <></> }}
                          margin={{ left: 0, right: 0 }}
                        />
                        <Typography
                          position="absolute"
                          sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          {(
                            (tossUpRingData[0].value /
                              tossUpRingData[1].value) *
                            100
                          ).toFixed(0)}
                          %
                        </Typography>
                      </Box>
                      <Typography fontSize="0.85rem" sx={{ mt: -1 }}>
                        {tossUpRingData[0].value} / {tossUpRingData[1].value}
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
            )}

            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Box>

          <Stack sx={{ height: "100%", position: "relative" }}>
            <Stack direction="row" gap={2}>
              <Paper sx={{ p: 2 }}>
                <Stack gap={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={0.5}
                    px={2}
                    py={1}
                    bgcolor="#f3f3f3"
                    mt={-2}
                    mx={-2}
                  >
                    <Typography>コール結果割合</Typography>
                  </Stack>
                  <CallStatsPieChart
                    showingCallResults={filter.showingCallResults}
                    data={pieChartData}
                  ></CallStatsPieChart>
                </Stack>
              </Paper>

              <Paper sx={{ width: "100%" }}>
                <CallStatsPieChartLegend
                  data={pieChartData.filter((data) =>
                    filter.showingCallResults.includes(data.id as CallResult),
                  )}
                ></CallStatsPieChartLegend>
              </Paper>
            </Stack>

            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Stack>
        </Stack>

        <Stack gap={4} minWidth={290}>
          <Box flexGrow={0} position="relative">
            <CallResultSummaryLarge
              answerCount={answerCount || 0}
              tossUpCount={tossUpCount || 0}
              appointmentCount={appointmentCount || 0}
            ></CallResultSummaryLarge>
            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Box>
          <CallProcessLogFilter
            handleFilterApply={handleFilterApply}
          ></CallProcessLogFilter>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
