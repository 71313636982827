import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, MobileStepper } from "@mui/material";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

function Carousel({
  cards,
  cardsPerPage,
  spacing = 2,
}: {
  cards: Array<React.ReactElement>;
  cardsPerPage: number;
  spacing: number;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState<
    "right" | "left" | undefined
  >("left");
  const totalPageNum =
    Math.floor(cards.length / cardsPerPage) +
    (cards.length % cardsPerPage === 0 ? 0 : 1);

  // these two functions handle changing the pages
  const handleNextPage = () => {
    setSlideDirection("left");
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setSlideDirection("right");
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    //  outer box that holds the carousel and the buttons
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          width: "100%",
          zIndex: 100,
          position: "relative",
        }}
      >
        {/* <IconButton
          onClick={handlePrevPage}
          sx={{ margin: 2 }}
          disabled={currentPage === 0}
        >
          <NavigateBeforeIcon />
        </IconButton> */}
        <Box sx={{ height: "100%" }}>
          {cards.map((card, index) => (
            <Box
              key={`card-${index}`}
              sx={{
                width: "100%",
                height: "100%",
                display: currentPage === index ? "block" : "none",
              }}
            >
              <Slide direction={slideDirection} in={currentPage === index}>
                <Stack
                  spacing={spacing}
                  direction="row"
                  alignContent="center"
                  justifyContent="center"
                  sx={{ width: "100%", height: "100%" }}
                >
                  {cards.slice(
                    index * cardsPerPage,
                    index * cardsPerPage + cardsPerPage,
                  )}
                </Stack>
              </Slide>
            </Box>
          ))}
        </Box>
        {/* <IconButton
          onClick={handleNextPage}
          sx={{
            margin: 2,
          }}
          disabled={
            currentPage >= Math.ceil((cards.length || 0) / cardsPerPage) - 1
          }
        >
          <NavigateNextIcon />
        </IconButton> */}
      </Box>
      <MobileStepper
        variant="dots"
        steps={totalPageNum}
        position="static"
        activeStep={currentPage}
        sx={{ maxWidth: 400, flexGrow: 1, mx: "auto", bgcolor: "transparent" }}
        nextButton={
          <Button
            size="small"
            onClick={handleNextPage}
            disabled={currentPage === totalPageNum - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
}

export default Carousel;
