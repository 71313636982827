import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { NavigateNext } from "@mui/icons-material";
import { Link } from "react-router-dom";

export interface BreadcrumbRef {
  title: string;
  navigateTo?: string;
}

interface Props {
  breadcrumbRefs: BreadcrumbRef[];
}

export const DSBreadcrumbs: React.FC<Props> = React.memo(function Breadcrumbs({
  breadcrumbRefs,
}: Props) {
  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
    >
      <div></div>
      {breadcrumbRefs.map((breadcrumbRef) => {
        if (breadcrumbRef.navigateTo !== undefined) {
          return (
            <Link
              color="inherit"
              key={breadcrumbRef.title}
              to={breadcrumbRef.navigateTo}
            >
              <Typography fontSize="0.875rem">{breadcrumbRef.title}</Typography>
            </Link>
          );
        } else {
          return (
            <StyledTypography key={breadcrumbRef.title} color="text.primary">
              {breadcrumbRef.title}
            </StyledTypography>
          );
        }
      })}
    </StyledBreadcrumbs>
  );
});

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  margin: 1%;
`;

const StyledTypography = styled(Typography)`
  font-size: 0.875rem;
`;
