import { useCallListMetadata } from "@/hooks/useCallList";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { useScripts } from "@/hooks/useScripts";
import { RedialContact,AiNurturing } from "@/models/Call";
import { RootState } from "@/store/store";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, jaJP } from "@mui/x-data-grid";
import React, {  useState } from "react";
import { useSelector } from "react-redux";

const RedialAccordion = React.memo(
  ({
    columns,
    group,
    checked,
    handleChangeRadioButton: handleChangeRadioButton,
    fetchContactList,
  }: {
    columns: GridColDef[];
    group: AiNurturing;
    checked: boolean;
    handleChangeRadioButton: (key: string) => void;
    fetchContactList: (listId:string, scriptId:string, limit:number, offset:number, sortField:string, sort:"asc" | "desc" ) => Promise<RedialContact>;
  }) => {
    const callResultLabels = useSelector(
      (state: RootState) => state.company.config.customCallResultLabels,
    );
    const users = useCompanyUsers();
    const callListMetadata = useCallListMetadata({ disableLoading: true });
    const scriptMetadata = useScripts({ disableLoading: true });
    const [contactList, setContactList] = useState<RedialContact>({ contacts: [], total: 0 });
    const [pageModel, setPageModel] = useState({
      page: 0,
      pageSize: 10,
      sortField: "" as string,
      sort: "asc" as "asc" | "desc",
    });

    const handleAccordionChange = async (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        console.log("expanded", group.contactListId);
        const contactList = await fetchContactList(group.contactListId, group.scriptId, pageModel.pageSize, pageModel.page, pageModel.sortField, pageModel.sort);
        setContactList(contactList);
      }
    };

    return (
      <Accordion sx={{ "& .MuiAccordionSummary-content": { my: 0 } }} onChange={
        handleAccordionChange
      }>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid
            container
            columns={12}
            minWidth={640}
            sx={{ textAlign: "left", whiteSpace: "nowrap" }}
          >
            <Grid item xs={1} display="flex" alignItems="center" gap={1}>
              <Radio
                size="small"
                checked={checked}
                onChange={() => handleChangeRadioButton(group.contactListId)}
                onClick={(e) => e.stopPropagation()}
              ></Radio>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                予定日:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {"---"}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                コールリスト:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {callListMetadata[group.contactListId]?.name}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                スクリプト:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {scriptMetadata[group.scriptId]?.name}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            columns={columns}
            rows={contactList.contacts.map((history, k) => ({
              id: k,
              name: history.name,
              phoneNumber: history.phone_number,
              nextCallDate: history.latestCallNextCallScheduledAt,
              callNoteResult: callResultLabels[history.latestCallNoteResult] || history.latestCallNoteResult,
              operatorId: users[history.latestCallNoteResult]?.name || "---",
              callCount: history.callCount + "回",
            }))}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            paginationMode="server"
            hideFooter={contactList.total<= 10}
            pageSizeOptions={[10, 20, 50, 100]}
            rowHeight={36}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onPaginationModelChange={(model) => {
              setPageModel({ ...pageModel, pageSize: model.pageSize, page: model.page });
              fetchContactList(group.contactListId, group.scriptId, model.pageSize, model.page, pageModel.sortField, pageModel.sort);
            }}
            onSortModelChange={(sortModel) => {
              setPageModel({ ...pageModel, sortField: sortModel[0].field, sort: sortModel[0].sort ?? "asc" });
              fetchContactList(group.contactListId, group.scriptId,pageModel.pageSize, pageModel.page, sortModel[0].field, sortModel[0].sort ?? "asc");
            }}
          ></DataGrid>
        </AccordionDetails>
      </Accordion>
    );
  },
);

export default RedialAccordion;
