import { request } from "@/models/telai-backend/client";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { setCompanyPhoneNumbers } from "@/store/companySlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let fetching = false;

export const useCompanyPhoneNumbers = ({
  disableLoading = false,
}: { disableLoading?: boolean } = {}) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const companyPhoneNumbers = useSelector(
    (state: RootState) => state.company.phoneNumbers,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn || fetching) return;
      fetching = true;

      if (!disableLoading)
        dispatch(
          setLoadingBackdrop({ key: "useCompanyPhoneNumbers", state: true }),
        );

      const res = await request({
        path: "/phone_numbers/own",
        httpMethod: "get",
      });
      const phoneNumbers = res.data.phoneNumbers;
      console.log(phoneNumbers);
      dispatch(setCompanyPhoneNumbers(phoneNumbers));

      if (!disableLoading)
        dispatch(
          setLoadingBackdrop({ key: "useCompanyPhoneNumbers", state: false }),
        );
      fetching = false;
    };
    fetch();
  }, [dispatch, isSignedIn, disableLoading]);

  return companyPhoneNumbers;
};
