import LoadingBackdrop from "@/components/LoadingBackdrop";
import { auth } from "@/google/auth";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { AppDispatch, RootState } from "@/store/store";
import { getCurrentUser } from "@/store/userSlice";
import callStateBgColors from "@/styles/callStateColors";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const callStateOrder = Object.keys(callStateBgColors);

const AssignedUsersPopup = () => {
  const [callIncoming, setCallIncoming] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const userStates = useCompanyUsers();
  const assignedPid = useMemo(() => {
    const assignedCallBatchIds = userStates[user.id]?.assignedCallBatchIds;
    if (assignedCallBatchIds.length < 1) return null;
    return assignedCallBatchIds[0];
  }, [userStates, user.id]);
  const userStatesSorted = useMemo(
    () =>
      Object.entries(userStates).sort((a, b) => {
        const indexA = callStateOrder.indexOf(a[1].status);
        const indexB = callStateOrder.indexOf(b[1].status);
        return indexA - indexB;
      }),
    [userStates],
  );
  const userStatesFiltered = useMemo(
    () =>
      // 同じプロセスに割り当てられているユーザーだけ表示
      Object.fromEntries(
        userStatesSorted.filter(
          (userState) =>
            assignedPid &&
            userState[1].assignedCallBatchIds.includes(assignedPid),
        ),
      ),
    [userStatesSorted, assignedPid],
  );
  const companyUsers = useCompanyUsers();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCurrentUser());

    // ポップアップを閉じる時に親ウィンドウへ通知
    window.onclose = () => window.opener.postMessage("popup-close");

    // ログアウトしたら閉じる
    onAuthStateChanged(auth, (user) => {
      if (!user) window.close();
    });

    // 親ウィンドウがリロード/閉じたら閉じる
    (window.opener as Window).onclose = () => window.close();
    (window.opener as Window).onbeforeunload = () => window.close();
  }, []);

  useEffect(() => {
    // プロセスがkillされたら閉じる
    if (userStates[user.id]?.status === "FREE") window.close();
  }, [userStates[user.id]]);

  // ユーザー状態が同期されるまでローディング
  useEffect(() => {
    if (!Object.keys(userStatesFiltered).length) setLoading(true);
    else setLoading(false);
  }, [Object.keys(userStatesFiltered).length]);

  // const handleClickMoveToCallScreen = () => {
  //   if (navigator.serviceWorker.controller) {
  //     console.log("post message: focusPWA")
  //     navigator.serviceWorker.controller.postMessage('focusPWA');
  //   }
  // }

  self.addEventListener("message", (event) => {
    if (event.data === "call incoming") {
      setCallIncoming(true);
      setTimeout(() => {
        setCallIncoming(false);
      }, 10000);
    }
  });

  return (
    <Stack p={2}>
      <Grid
        container
        columns={12}
        p={2}
        mb={2}
        rowGap={1}
        bgcolor="#E0E0E0"
        borderRadius={2}
      >
        {Object.entries(userStatesFiltered || []).map(([uid, userState], i) => (
          <Grid item xs={2} key={i}>
            <Stack>
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  mx: "auto",
                  bgcolor: callStateBgColors[userState.status],
                }}
              >
                <Typography fontSize="0.85rem">
                  {(companyUsers || {})[uid]?.name}
                </Typography>
              </Avatar>
              <Typography fontSize="0.80rem">
                {(companyUsers || {})[uid]?.name}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} px={2}>
        <Grid item xs={6}>
          <Stack gap={1}>
            <Button onClick={() => {}} variant="contained">
              離席
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack>
            {Object.entries(callStateBgColors).map(([state, color]) => (
              <Grid container>
                <Grid xs={2}>
                  <Box bgcolor={color} width={16} height={16}></Box>
                </Grid>
                <Grid xs={10} textAlign="left">
                  <Typography fontSize=".8rem">{state}</Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Modal open={callIncoming}>
        <Box
          borderRadius={2}
          bgcolor="#FFEBEE"
          p={2}
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
          minWidth={300}
        >
          <Typography color="error" fontWeight="bold">
            トスアップされました。メイン画面へ移動してください
          </Typography>
        </Box>
      </Modal>

      <Modal open={callIncoming}>
        <Box
          borderRadius={2}
          bgcolor="#FFEBEE"
          p={2}
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
          minWidth={300}
        >
          <Typography color="error" fontWeight="bold">
            トスアップされました。メイン画面へ移動してください
          </Typography>
        </Box>
      </Modal>

      <LoadingBackdrop
        loading={loading}
        message={"コールプロセスへ割り当てられました。情報を取得中です。"}
      ></LoadingBackdrop>
    </Stack>
  );
};

export default AssignedUsersPopup;
