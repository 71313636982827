// import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { RedialContact } from "@/models/Call";
import { request } from "@/models/telai-backend/client";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import {
  CallOutlined,
  CheckOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const RedialManual = React.memo(
  ({
    nurturingCalls,
    reFetchNurturingCall,
    changePage,
  }: {
    nurturingCalls: RedialContact;
    reFetchNurturingCall: (limit:number, offset:number) => void;
    changePage: (limit:number, offset:number, sortField:string, sort:"asc" | "desc" ) => void;
  }) => {
    const callResultLabels = useSelector(
      (state: RootState) => state.company.config.customCallResultLabels,
    );
    // const users = useCompanyUsers();
    const { tenantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    // 手動再コール
    const [manualRedialDialog, setManualRedialDialog] = useState(false);
    const [manualRedialSelection, setManualRedialSelection] = useState([]);
    const [pageModel, setPageModel] = useState({
      page: 0,
      pageSize: 10,
      sortField: "" as string,
      sort : "asc" as "asc" | "desc", 
    });

    const manualRedialColumns: GridColDef[] = [
      { field: "name", headerName: "会社名", width: 150 },
      // { field: "phoneNumber", headerName: "電話番号", width: 100 },
      {
        field: "latestCallNoteResult",
        headerName: "コール結果",
        width: 160,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? callResultLabels[params.value] : "",
      },
      {
        field: "latestCallCalledAt",
        headerName: "通話日付",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "",
      },
      {
        field: "latestCallNextCallScheduledAt",
        headerName: "次回架電予定",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "未設定",
      },
      // { field: "operatorId", headerName: "担当者", width: 120 },
      { field: "callCount", headerName: "再コール回数", width: 120 },
    ] as const;

    // 選択されたコール履歴を再コール済みとしてマークする
    const handleRegisterNextCalls = useCallback(async () => {
      console.log(
        "manualRedialSelection",
        manualRedialSelection[0],
        manualRedialSelection,
      );

      setManualRedialDialog(false);
      dispatch(
        setLoadingBackdrop({ key: "handleRegisterNextCalls", state: true }),
      );
      try {
        for (const call of manualRedialSelection) {
          console.log("nurturingCall", manualRedialSelection);
          const latestCallId = nurturingCalls.contacts.find(
            (row) => row.id === call,
          )?.latestCallId;
          await request({
            path: "/calls/{callId}/note",
            httpMethod: "put",
            params: {
              paths: { callId: latestCallId },
              body: {
                nextCallCompleted: true,
              },
            },
          });
        }
        // 再コールリストを再取得
        await reFetchNurturingCall(pageModel.pageSize, pageModel.page * pageModel.pageSize);
        await setManualRedialSelection([]);

        await dispatch(
          setSnackbar({
            text: "正常に登録しました。",
            open: true,
            severity: "success",
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "登録処理中にエラーが発生しました。",
            open: true,
            severity: "error",
          }),
        );
      } finally {
        dispatch(
          setLoadingBackdrop({ key: "handleRegisterNextCalls", state: false }),
        );
      }
    }, [manualRedialSelection, nurturingCalls]);

    console.log("nurturingCalls", nurturingCalls);

    const manualRedialRows = useMemo(() => {
      // latestCallNoteNextCallCompletedがtrueの場合は除外
      console.log("nurturingCalls", nurturingCalls);
      return nurturingCalls.contacts
    }, [nurturingCalls.contacts]);

    const manualCallNavigateUrl = useMemo(() => {
      const phoneNumber = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0],
      )?.phone_number;
      // const ids = manualRedialRows.find((row) => row.id === manualRedialSelection[0])?.prevCallSids.join(",")
      const ids = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0],
      )?.id;
      if (!phoneNumber) return `/${tenantId}/manualcall`;
      return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${ids ? `&ids=${ids}` : ""}`;
    }, [manualRedialRows, manualRedialSelection, tenantId]);

    return (
      <Box sx={{ mx: "auto", position: "relative", display: "inline-block" }}>
        <Stack direction="row" mb={2}>
          <Stack alignItems="center" direction="row">
            <CallOutlined sx={{ mr: 1 }}></CallOutlined>
            <Typography textAlign="left" fontWeight="600" fontSize="1.1rem">
              手動再コール
            </Typography>
          </Stack>
        </Stack>
        <Stack alignContent="center" flexWrap="wrap" gap={2}>
          <Paper sx={{ overflow: "hidden" }}>
            <DataGrid
              columns={manualRedialColumns}
              rows={manualRedialRows}
              rowCount={nurturingCalls.total}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10},
                },
              }}
              sx={{
                scrollbarColor: "#ddd #f6f6f6",
                scrollbarWidth: "thin",
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowHeight={36}
              checkboxSelection
              rowSelectionModel={manualRedialSelection}
              onRowSelectionModelChange={(model) =>
                setManualRedialSelection(model)
              }
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onPaginationModelChange={(model) => {
                console.log("paginationModel", pageModel);
                setPageModel((prev) => ({
                  ...prev,
                  pageSize: model.pageSize,
                  page: model.page,
                }));
                changePage(model.pageSize, model.page * model.pageSize, pageModel.sortField, pageModel.sort);
              }}
              onSortModelChange={(model) => {
                console.log("sortModel", pageModel);
                setPageModel((prev) => ({
                  ...prev,
                  sortField: model[0].field,
                  sort: model[0].sort ?? "asc",
                }));
                changePage(pageModel.pageSize, pageModel.page * pageModel.pageSize, model[0].field, model[0].sort ?? "asc");
              }}
            ></DataGrid>
          </Paper>
          <Stack direction="row" justifyContent="end" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<PhoneOutlined></PhoneOutlined>}
              disabled={manualRedialSelection.length !== 1}
              onClick={() => navigate(manualCallNavigateUrl)}
            >
              手動コールページへ移動
            </Button>

            <Button
              variant="contained"
              startIcon={<CheckOutlined></CheckOutlined>}
              disabled={manualRedialSelection.length < 1}
              onClick={() => setManualRedialDialog(true)}
            >
              {manualRedialSelection.length}件を再コール済みとして登録
            </Button>
          </Stack>
        </Stack>

        <Dialog open={manualRedialDialog}>
          <DialogTitle display="flex" gap={1} alignItems="center">
            <CheckOutlined></CheckOutlined>
            再コール登録
          </DialogTitle>
          <DialogContent>
            <Typography>
              {manualRedialSelection.length}
              件の項目を再コール済みとして登録しますか？
            </Typography>
            <Typography>
              再コール済みとして登録された項目は再コールリストから除外されます。
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setManualRedialDialog(false)}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleRegisterNextCalls()}
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  },
);

export default RedialManual;
