import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { useScripts } from "@/hooks/useScripts";
import { getScriptLines, updateScript } from "@/store/callSlice";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { PermPhoneMsgOutlined, PlayArrow } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { request } from "@/models/telai-backend/client";
import type { ScriptLine } from "@/models/Script";

export const ScriptDetail = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const urlParameteres = useParams();
  const scriptId = useMemo(() => urlParameteres.id, [urlParameteres]);
  const scripts = useScripts();
  const script = useMemo(() => scripts[scriptId], [scriptId, scripts]);
  const scriptLines = useSelector(
    (state: RootState) => state.call.scriptLines[scriptId],
  );
  const dispatch = useDispatch<AppDispatch>();
  const { tenantId } = useParams();
  const [prevSelectedLines, setPrevSelectedLines] = useState<{
    [alphabet: string]: number;
  }>({});
  const [selectedLines, setSelectedLines] = useState<{
    [alphabet: string]: number;
  }>({});
  const changedLines = useMemo(() => {
    const changedLines = {};
    Object.entries(selectedLines).forEach(([alphabet, index]) => {
      if (index !== prevSelectedLines[alphabet]) changedLines[alphabet] = index;
    });
    return changedLines;
  }, [selectedLines, prevSelectedLines]);
  const changedLineNum = useMemo(() => {
    return Object.keys(changedLines).length;
  }, [changedLines]);

  const breadcrumbRefs: BreadcrumbRef[] = [
    { title: "スクリプト設定", navigateTo: `/${tenantId}/script` },
    { title: "詳細" },
  ];

  const handleClickPlay = async (line: ScriptLine) => {
    try {
      const res = await request({
        path: "/scripts/{scriptId}/messages/{scriptMessageId}/variants/{variantId}/audio",
        httpMethod: "get",
        responseType: "blob",
        params: {
          paths: {
            scriptId: line.scriptId,
            scriptMessageId: line.id,
            variantId: line.variantId,
          },
        },
      });

      if (res.result === "success") {
        const audioBlob = res.data;
        const url = URL.createObjectURL(audioBlob);
        const audioElement = new Audio(url);
        audioRef.current = audioElement;
        audioElement.play();
        audioElement.onended = () => {
          URL.revokeObjectURL(url);
          audioRef.current = null;
        };
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 音声が別ページに遷移した際に再生を停止する
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const handleClickApply = async () => {
    dispatch(
      setLoadingBackdrop({ key: "ScriptDetailHandleClickApply", state: true }),
    );
    await dispatch(
      updateScript({
        companyId: user.tenantId,
        scriptId,
        script: {
          selectedLines,
        },
      }),
    );
    dispatch(
      setLoadingBackdrop({ key: "ScriptDetailHandleClickApply", state: false }),
    );
    dispatch(
      setSnackbar({
        open: true,
        text: "変更を適用しました",
        severity: "success",
      }),
    );
    setPrevSelectedLines(selectedLines);
  };

  useEffect(() => {
    console.log("ScriptDetail useEffect", scriptId, user, scriptLines);
    if (!scriptId) return;

    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "ScriptDetail", state: true }));
      console.log("fetching script lines");
      await dispatch(getScriptLines({ scriptId }));
      dispatch(setLoadingBackdrop({ key: "ScriptDetail", state: false }));
    };
    fetch();
  }, [user.tenantId, scriptId]);

  useEffect(() => {
    if (!scriptLines || !script) return;
    if (script.selectedLines) {
      setPrevSelectedLines(script.selectedLines);
      setSelectedLines(script.selectedLines);
      return;
    }
    const newSelectedLines = {};
    Object.entries(scriptLines).forEach(([alphabet]) => {
      newSelectedLines[alphabet] = 0;
    });
    setPrevSelectedLines(newSelectedLines);
    setSelectedLines(newSelectedLines);
  }, [scriptLines, script]);

  return (
    <Container sx={{ py: 4 }} maxWidth="lg">
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack mt={4}>
        <Typography textAlign="left">スクリプト名</Typography>
        <Typography textAlign="left" variant="h5">
          {script?.name}
        </Typography>
      </Stack>
      <Grid container spacing={2} mt={1} columns={10}>
        <Grid item xs={2}>
          <Stack
            bgcolor="#FFF"
            borderRadius={2}
            overflow="hidden"
            border="1px solid #BDBDBD"
          >
            <Box bgcolor="#E1E1E1" p={1.5}>
              <Typography>トスアップ回数</Typography>
            </Box>
            <Box p={1.5}>
              <Typography variant="h5">
                {script?.handoffCount ?? 0}回
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            bgcolor="#FFF"
            borderRadius={2}
            overflow="hidden"
            border="1px solid #BDBDBD"
          >
            <Box bgcolor="#E1E1E1" p={1.5}>
              <Typography>トスアップ率</Typography>
            </Box>
            <Box p={1.5}>
              <Typography variant="h5">
                {(
                  ((script?.handoffCount ?? 0) / (script?.usageCount ?? 1)) *
                  100
                ).toFixed(2)}
                %
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            bgcolor="#FFF"
            borderRadius={2}
            overflow="hidden"
            border="1px solid #BDBDBD"
          >
            <Box bgcolor="#E1E1E1" p={1.5}>
              <Typography>使用回数</Typography>
            </Box>
            <Box p={1.5}>
              <Typography variant="h5">{script?.usageCount ?? 0}回</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            bgcolor="#FFF"
            borderRadius={2}
            overflow="hidden"
            border="1px solid #BDBDBD"
          >
            <Box bgcolor="#E1E1E1" p={1.5}>
              <Typography>アポ数</Typography>
            </Box>
            <Box p={1.5}>
              <Typography variant="h5">
                {script?.appointmentAchievedCount ?? 0}回
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            bgcolor="#FFF"
            borderRadius={2}
            overflow="hidden"
            border="1px solid #BDBDBD"
          >
            <Box bgcolor="#E1E1E1" p={1.5}>
              <Typography>アポ率</Typography>
            </Box>
            <Box p={1.5}>
              <Typography variant="h5">
                {(
                  ((script?.appointmentAchievedCount ?? 0) /
                    (script?.usageCount ?? 1)) *
                  100
                ).toFixed(2)}
                %
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" gap={4} mt={3} mb={2}>
        <Typography variant="h6" textAlign="left">
          <PermPhoneMsgOutlined
            sx={{ mr: 1, mb: -0.75, fontSize: "1.75rem" }}
          ></PermPhoneMsgOutlined>
          ボイス一覧
        </Typography>
        <Button
          variant="contained"
          disabled={!changedLineNum}
          onClick={handleClickApply}
        >
          変更を適用
          {changedLineNum ? ` (${changedLineNum}件)` : ""}
        </Button>
      </Stack>

      <Typography textAlign="left" mb={2} fontSize="0.85rem">
        複数のボイスが用意されているアルファベットは、クリックして有効なボイスを変更可能です。
      </Typography>

      <Stack gap={2} pb={6}>
        {scriptLines &&
          scriptLines.map((line) => (
            <Paper sx={{ overflow: "hidden" }} key={line.id}>
              <Box display="flex" alignItems="center">
                <Box
                  py={1}
                  minWidth={40}
                  maxWidth={40}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="#E1E1E1"
                >
                  {line.id}
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  <Typography px={2} overflow="hidden" textOverflow="ellipsis">
                    {line.content}
                  </Typography>
                </Stack>
                <Button
                  color="greyDarken"
                  sx={{
                    py: 1,
                    px: 2,
                    pl: "auto",
                    display: "flex",
                    alignItems: "center",
                    ml: "auto",
                    borderRadius: 0,
                  }}
                  variant="contained"
                  onClick={() => handleClickPlay(line)}
                >
                  <PlayArrow htmlColor="#fff"></PlayArrow>
                </Button>
              </Box>
            </Paper>
          ))}
      </Stack>
    </Container>
  );
};
